import { Action, State, StateContext } from '@ngxs/store';
import { StartShipmentStateModel } from '@store/mobile/start-shipment/start-shipment.model';
import { ResetStartShipment, ScanStartShipment } from '@store/mobile/start-shipment/start-shipment.actions';
import { Injectable } from '@angular/core';

@State<StartShipmentStateModel>({
  name: 'startShipment',
})
@Injectable()
export class StartShipmentState {
  @Action(ScanStartShipment)
  public setScannedCode(ctx: StateContext<StartShipmentStateModel>, { shipment }: ScanStartShipment): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shipment,
    });
  }

  @Action(ResetStartShipment)
  public reset(ctx: StateContext<Partial<StartShipmentStateModel>>): void {
    ctx.setState({});
  }
}
